@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
	width: 6px;
	transition: all 0.3s ease-in-out;
	appearance: none;
}

.active-sidebar {
	background: linear-gradient(to right, #03259b, #2b59fa);
}

.switch-on {
	background-color: red;
}

.switch-off {
	background-color: gray;
}

.refresh-button-shadow {
  box-shadow: 0px 2px 8px -4px #1018280d;
  box-shadow: 0px 6px 15px -2px #a2b5da26;
}

.analytics-card {
  box-shadow: 0px 4px 8px -4px #10182808;
  box-shadow: 0px 8px 20px -5px #a2b5da40;
}
